<template>
    <!-- The Modal -->
    <div class="modal fade" id="configResultModal">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <!-- Modal Header -->
                <div class="modal-header">
                    <h4 class="modal-title">Browser Results</h4>
                    <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        @click="$emit('close')"
                    >
                        &times;
                    </button>
                </div>

                <!-- Modal body -->
                <div class="modal-body">
                    <div class="container-fluid">
                        <div class="row justify-content-center">
                            <div class="col-md-8 col-sm-12">
                                <ul class="nav nav-tabs">
                                    <li
                                        v-for="(point, index) in item.points"
                                        class="nav-item"
                                        :key="index"
                                    >
                                        <a
                                            :class="`nav-link ${
                                                active === point ? 'active' : ''
                                            }`"
                                            :href="`#${point}`"
                                            @click="change(point)"
                                            data-toggle="tab"
                                            >{{ point }}</a
                                        >
                                    </li>
                                    <li v-if="item.verified" class="nav-item">
                                        <a
                                            :class="`nav-link ${
                                                active === 'results'
                                                    ? 'active'
                                                    : ''
                                            }`"
                                            href="#results"
                                            @click="change('results')"
                                            data-toggle="tab"
                                            >Results</a
                                        >
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="tab-content mt-4">
                            <div
                                :class="`tab-pane fade ${
                                    active === 'results' ? 'show active' : ''
                                }`"
                                id="results"
                            >
                                <div
                                    v-for="(row, idx) in data"
                                    :key="idx"
                                    class="shadow-sm mb-4"
                                >
                                    <div v-if="row.type === 'tweet'">
                                        <div
                                            class="d-flex p-2"
                                            style="gap: 15px"
                                        >
                                            <div>
                                                <img
                                                    :src="
                                                        row.user
                                                            .profile_photo_url
                                                    "
                                                />
                                            </div>
                                            <div>
                                                <div>
                                                    <strong
                                                        href=""
                                                        v-if="row.user"
                                                        >{{
                                                            row.user.name
                                                        }}</strong
                                                    >
                                                    <span v-if="row.created"
                                                        ><small>{{
                                                            toDate(row.created)
                                                        }}</small></span
                                                    >
                                                </div>
                                                <div>{{ row.text }}</div>
                                                <div
                                                    v-if="
                                                        row.media &&
                                                        row.media.length
                                                    "
                                                >
                                                    <a
                                                        class="mr-2"
                                                        target="_blank"
                                                        :href="link"
                                                        v-for="(
                                                            link, index
                                                        ) in row.media"
                                                        :key="index"
                                                        >{{ link }}</a
                                                    >
                                                </div>
                                                <a
                                                    href="#"
                                                    @click="
                                                        row.showRetweets =
                                                            !row.showRetweets;
                                                        $forceUpdate();
                                                    "
                                                    >Retweets:
                                                    {{
                                                        row.retweets
                                                            ? row.retweets
                                                                  .length
                                                            : 0
                                                    }}</a
                                                >

                                                <div v-if="row.showRetweets">
                                                    <div
                                                        v-for="(
                                                            user, ridx
                                                        ) in row.retweets"
                                                        :key="ridx"
                                                    >
                                                        <div
                                                            class="d-flex p-2"
                                                            style="gap: 15px"
                                                        >
                                                            <div>
                                                                <img
                                                                    width="50px"
                                                                    :src="
                                                                        user.profile_photo_url
                                                                    "
                                                                />
                                                            </div>
                                                            <div>
                                                                <div>
                                                                    <a
                                                                        target="_blank"
                                                                        href=""
                                                                        >{{
                                                                            user.name
                                                                        }}</a
                                                                    >
                                                                    <span
                                                                        ><small
                                                                            >{{
                                                                                toDate(
                                                                                    user.created
                                                                                )
                                                                            }}</small
                                                                        ></span
                                                                    >
                                                                </div>
                                                                <div>
                                                                    {{
                                                                        user.screen_name
                                                                    }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="row.type === 'post'">
                                        <div
                                            class="d-flex p-2"
                                            style="gap: 15px"
                                        >
                                            <div>
                                                <div>
                                                    <strong
                                                        href=""
                                                        v-if="row.user"
                                                        >{{
                                                            row.user.name
                                                        }}</strong
                                                    >
                                                    <span v-if="row.created"
                                                        ><small>{{
                                                            toDate(row.created)
                                                        }}</small></span
                                                    >
                                                </div>
                                                <div>{{ row.message }}</div>
                                                <div v-if="row.content">
                                                    <img
                                                        v-if="
                                                            row.content
                                                                .photo_url
                                                        "
                                                        width="350px"
                                                        :src="
                                                            row.content
                                                                .photo_url
                                                        "
                                                    />
                                                    <img
                                                        v-if="
                                                            row.content
                                                                .share_url
                                                        "
                                                        width="350px"
                                                        :src="
                                                            row.content
                                                                .share_url
                                                        "
                                                    />
                                                    <img
                                                        v-if="
                                                            row.content
                                                                .thumbnail
                                                        "
                                                        width="350px"
                                                        :src="
                                                            row.content
                                                                .thumbnail
                                                        "
                                                    />
                                                    <video
                                                        v-if="
                                                            row.content
                                                                .video_url
                                                        "
                                                        width="350px"
                                                        :src="
                                                            row.content
                                                                .video_url
                                                        "
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                v-for="(point, index) in item.points"
                                :class="`tab-pane fade ${
                                    active === point ? 'show active' : ''
                                }`"
                                :id="point"
                                :key="index"
                            >
                                <div>
                                    <div
                                        v-for="(row, idx) in data"
                                        :key="idx"
                                        :class="`${
                                            ['Videos', 'Photos'].includes(point)
                                                ? 'col-6 col-md-4 col-lg-3 col-xl-2 d-flex'
                                                : 'shadow-sm mb-4'
                                        }`"
                                        cols="6"
                                    >
                                        <div
                                            v-if="
                                                row.engine === 'google' ||
                                                row.engine === 'bing'
                                            "
                                        >
                                            <div v-if="row.type === 'result'">
                                                <b-row class="m-2">
                                                    <b-col
                                                        cols="2"
                                                        v-if="row.thumbnails"
                                                    >
                                                        <img
                                                            :src="
                                                                row
                                                                    .thumbnails[0]
                                                            "
                                                            width="100"
                                                        />
                                                    </b-col>
                                                    <b-col cols="8">
                                                        <b-row>
                                                            <b-col>
                                                                <b-row>
                                                                    <b-col>
                                                                        <b-row>
                                                                            <h4>
                                                                                <a
                                                                                    :href="
                                                                                        row.url
                                                                                    "
                                                                                    >{{
                                                                                        row.title
                                                                                    }}</a
                                                                                >
                                                                            </h4>
                                                                        </b-row>
                                                                        <b-row>
                                                                            <a
                                                                                :href="
                                                                                    row.url
                                                                                "
                                                                            >
                                                                                {{
                                                                                    row.displayed_url
                                                                                }}
                                                                            </a>
                                                                        </b-row>
                                                                        <b-row>
                                                                            {{
                                                                                row.snippet
                                                                            }}
                                                                            <a
                                                                                :href="
                                                                                    row.url
                                                                                "
                                                                                >View
                                                                                more</a
                                                                            >
                                                                        </b-row>
                                                                    </b-col>
                                                                </b-row>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                </b-row>
                                            </div>
                                            <div
                                                v-if="
                                                    row.type ===
                                                    'related_search'
                                                "
                                            >
                                                <h4 class="m-4 p-4">
                                                    {{ row.query }} -
                                                    <a
                                                        :href="`https://www.google.com/${row.url}`"
                                                        target="_blank"
                                                        >See more</a
                                                    >
                                                </h4>
                                            </div>
                                            <div v-if="row.type === 'video'">
                                                <b-row class="m-2">
                                                    <b-col cols="8">
                                                        <b-row>
                                                            <b-col>
                                                                <b-row>
                                                                    <b-col>
                                                                        <b-row>
                                                                            <h4>
                                                                                {{
                                                                                    row.title
                                                                                }}
                                                                            </h4>
                                                                        </b-row>
                                                                        <b-row>
                                                                            <carousel-video
                                                                                :videos="
                                                                                    row.videos
                                                                                "
                                                                            ></carousel-video>
                                                                        </b-row>

                                                                        <b-row>
                                                                            <a
                                                                                :href="
                                                                                    row.url
                                                                                "
                                                                                >View
                                                                                more</a
                                                                            >
                                                                        </b-row>
                                                                    </b-col>
                                                                </b-row>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                </b-row>
                                            </div>
                                        </div>
                                        <div
                                            v-if="row.engine === 'yahoo'"
                                            class="m-4"
                                        >
                                            <div v-if="row.type === 'result'">
                                                <a :href="row.mainLink">
                                                    <h6>{{ row.title }}</h6>
                                                </a>
                                                <p>{{ row.description }}</p>
                                                <div
                                                    v-if="
                                                        row.additionalLinks
                                                            .length
                                                    "
                                                >
                                                    Additional Links
                                                    <div
                                                        v-for="(
                                                            links, index
                                                        ) in row.additionalLinks"
                                                        :key="index"
                                                    >
                                                        <a :href="links.url">{{
                                                            links.text
                                                        }}</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                v-if="
                                                    row.type ===
                                                    'related_search'
                                                "
                                            >
                                                <h4 class="m-4 p-4">
                                                    <a
                                                        :href="row.href"
                                                        target="_blank"
                                                        >{{ row.text }}</a
                                                    >
                                                </h4>
                                            </div>
                                            <div v-if="row.type === 'video'">
                                                <div class="container mt-5">
                                                    <div
                                                        class="card mb-3"
                                                        style="max-width: 540px"
                                                    >
                                                        <div class="row g-0">
                                                            <div
                                                                class="col-md-4"
                                                            >
                                                                <img
                                                                    :src="
                                                                        row.imageUrl
                                                                    "
                                                                    class="img-fluid rounded-start"
                                                                    alt="Video thumbnail"
                                                                />
                                                            </div>
                                                            <div
                                                                class="col-md-8"
                                                            >
                                                                <div
                                                                    class="card-body"
                                                                >
                                                                    <h5
                                                                        class="card-title"
                                                                    >
                                                                        {{
                                                                            row.title
                                                                        }}
                                                                    </h5>
                                                                    <p
                                                                        class="card-text"
                                                                    >
                                                                        {{
                                                                            row.date
                                                                        }}
                                                                        |
                                                                        {{
                                                                            row.views
                                                                        }}
                                                                    </p>
                                                                    <p
                                                                        class="card-text"
                                                                    >
                                                                        <small
                                                                            class="text-muted"
                                                                            >{{
                                                                                row.source
                                                                            }}</small
                                                                        >
                                                                    </p>
                                                                    <p
                                                                        class="card-text"
                                                                    >
                                                                        <small
                                                                            class="text-muted"
                                                                            >{{
                                                                                row.timestamp
                                                                            }}</small
                                                                        >
                                                                    </p>
                                                                    <a
                                                                        :href="
                                                                            row.url
                                                                        "
                                                                        class="btn btn-primary"
                                                                        target="_blank"
                                                                        >Watch
                                                                        Video</a
                                                                    >
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="row.type === 'ad'">
                                                <div class="card mb-3">
                                                    <div class="card-body">
                                                        <h5 class="card-title">
                                                            <a
                                                                :href="
                                                                    row.mainLinkUrl
                                                                "
                                                                target="_blank"
                                                                >{{
                                                                    row.mainLinkText
                                                                }}</a
                                                            >
                                                        </h5>
                                                        <p class="card-text">
                                                            {{
                                                                row.promotionText
                                                            }}
                                                        </p>
                                                        <div
                                                            v-if="
                                                                row
                                                                    .additionalPromotions
                                                                    .length
                                                            "
                                                            class="row"
                                                        >
                                                            <div
                                                                v-for="(
                                                                    promotion,
                                                                    index
                                                                ) in row.additionalPromotions"
                                                                :key="index"
                                                                class="col-md-6"
                                                            >
                                                                <a
                                                                    :href="
                                                                        promotion.url
                                                                    "
                                                                    target="_blank"
                                                                    >{{
                                                                        promotion.text
                                                                    }}</a
                                                                >
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import swal from "sweetalert";
import CarouselVideo from "@/pages/tools/browser_search/CarouselVideo.vue";

export default {
    name: "ResultModal",
    components: { CarouselVideo },
    props: ["item"],
    data() {
        return {
            data: [],
            count: 0,
            page: 1,
            pageSize: 5,
            active: "",
        };
    },
    mounted() {
        this.active = "results";
        this.search();
    },
    computed: {
        columns() {
            return this.data.length ? Object.keys(this.data[0]) : [];
        },
    },
    methods: {
        formatNumber(num) {
            if (num == null) {
                return "N/A";
            }
            if (num >= 1e9) {
                return (num / 1e9).toFixed(2) + "B";
            } else if (num >= 1e6) {
                return (num / 1e6).toFixed(2) + "M";
            } else if (num >= 1e3) {
                return (num / 1e3).toFixed(2) + "K";
            } else {
                return num.toString();
            }
        },
        change(point) {
            this.active = point;
            this.search();
        },
        toDate(date) {
            return moment(date).format("YYYY-MM-DD hh:mm:ss");
        },
        verify(user) {
            axios
                .put(
                    `${this.$root.serverUrl}/admin/browser-search/${this.item._id}`,
                    { args: { verified: user.id } }
                )
                .then((resp) => {
                    if (resp.data && resp.data.error) {
                        swal({
                            title: "Error",
                            text: "Unable to process your request",
                            icon: "error",
                        });
                    } else {
                        swal({
                            title: "Success",
                            text: "Account verified",
                            icon: "success",
                        });
                        this.item.verified = user.id;
                        this.$forceUpdate();
                    }
                })
                .catch((err) => {
                    console.log(err);
                    swal({
                        title: "Error",
                        text: "Unable to process your request",
                        icon: "error",
                    });
                });
        },
        search() {
            let request = {
                $skip: (this.page - 1) * this.pageSize,
                $top: this.pageSize,
                filters: { point: this.active },
            };
            axios
                .get(
                    `${this.$root.serverUrl}/admin/browser-search/results/${this.item._id}`,
                    { params: request }
                )
                .then((resp) => {
                    this.data = resp.data.Items;
                    this.count = resp.data.Count;
                    this.$root.preloader = false;
                })
                .catch(() => {
                    swal({
                        title: "Error",
                        text: "Unable to process your request",
                        icon: "error",
                    });
                    this.$root.preloader = false;
                });
        },
    },
};
</script>

<style scoped>
.modal-dialog {
    height: 900px;
    display: flex;
    max-width: 1000px;
}
.modal-header {
    background: #0d0d48;
}
.modal-title {
    color: #ffffff;
}
.close {
    color: #ffffff;
}

.modal-body {
    overflow: scroll;
}
.image-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, 200px);
    gap: 10px;
    justify-content: center;
    padding: 20px;
    background-color: white;
    border: 1px solid #ddd;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.image-grid img {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    background-color: green;
    border-radius: 50%;
}

.fa-check {
    color: white;
    font-size: 10px;
}
.toggle-text {
    color: blue;
    cursor: pointer;
}
</style>

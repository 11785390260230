var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal fade",attrs:{"id":"configResultModal"}},[_c('div',{staticClass:"modal-dialog modal-lg"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_c('h4',{staticClass:"modal-title"},[_vm._v("Browser Results")]),_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" × ")])]),_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-8 col-sm-12"},[_c('ul',{staticClass:"nav nav-tabs"},[_vm._l((_vm.item.points),function(point,index){return _c('li',{key:index,staticClass:"nav-item"},[_c('a',{class:("nav-link " + (_vm.active === point ? 'active' : '')),attrs:{"href":("#" + point),"data-toggle":"tab"},on:{"click":function($event){return _vm.change(point)}}},[_vm._v(_vm._s(point))])])}),(_vm.item.verified)?_c('li',{staticClass:"nav-item"},[_c('a',{class:("nav-link " + (_vm.active === 'results'
                                                ? 'active'
                                                : '')),attrs:{"href":"#results","data-toggle":"tab"},on:{"click":function($event){return _vm.change('results')}}},[_vm._v("Results")])]):_vm._e()],2)])]),_c('div',{staticClass:"tab-content mt-4"},[_c('div',{class:("tab-pane fade " + (_vm.active === 'results' ? 'show active' : '')),attrs:{"id":"results"}},_vm._l((_vm.data),function(row,idx){return _c('div',{key:idx,staticClass:"shadow-sm mb-4"},[(row.type === 'tweet')?_c('div',[_c('div',{staticClass:"d-flex p-2",staticStyle:{"gap":"15px"}},[_c('div',[_c('img',{attrs:{"src":row.user
                                                        .profile_photo_url}})]),_c('div',[_c('div',[(row.user)?_c('strong',{attrs:{"href":""}},[_vm._v(_vm._s(row.user.name))]):_vm._e(),(row.created)?_c('span',[_c('small',[_vm._v(_vm._s(_vm.toDate(row.created)))])]):_vm._e()]),_c('div',[_vm._v(_vm._s(row.text))]),(
                                                    row.media &&
                                                    row.media.length
                                                )?_c('div',_vm._l((row.media),function(link,index){return _c('a',{key:index,staticClass:"mr-2",attrs:{"target":"_blank","href":link}},[_vm._v(_vm._s(link))])}),0):_vm._e(),_c('a',{attrs:{"href":"#"},on:{"click":function($event){row.showRetweets =
                                                        !row.showRetweets;
                                                    _vm.$forceUpdate();}}},[_vm._v("Retweets: "+_vm._s(row.retweets ? row.retweets .length : 0))]),(row.showRetweets)?_c('div',_vm._l((row.retweets),function(user,ridx){return _c('div',{key:ridx},[_c('div',{staticClass:"d-flex p-2",staticStyle:{"gap":"15px"}},[_c('div',[_c('img',{attrs:{"width":"50px","src":user.profile_photo_url}})]),_c('div',[_c('div',[_c('a',{attrs:{"target":"_blank","href":""}},[_vm._v(_vm._s(user.name))]),_c('span',[_c('small',[_vm._v(_vm._s(_vm.toDate( user.created )))])])]),_c('div',[_vm._v(" "+_vm._s(user.screen_name)+" ")])])])])}),0):_vm._e()])])]):_vm._e(),(row.type === 'post')?_c('div',[_c('div',{staticClass:"d-flex p-2",staticStyle:{"gap":"15px"}},[_c('div',[_c('div',[(row.user)?_c('strong',{attrs:{"href":""}},[_vm._v(_vm._s(row.user.name))]):_vm._e(),(row.created)?_c('span',[_c('small',[_vm._v(_vm._s(_vm.toDate(row.created)))])]):_vm._e()]),_c('div',[_vm._v(_vm._s(row.message))]),(row.content)?_c('div',[(
                                                        row.content
                                                            .photo_url
                                                    )?_c('img',{attrs:{"width":"350px","src":row.content
                                                            .photo_url}}):_vm._e(),(
                                                        row.content
                                                            .share_url
                                                    )?_c('img',{attrs:{"width":"350px","src":row.content
                                                            .share_url}}):_vm._e(),(
                                                        row.content
                                                            .thumbnail
                                                    )?_c('img',{attrs:{"width":"350px","src":row.content
                                                            .thumbnail}}):_vm._e(),(
                                                        row.content
                                                            .video_url
                                                    )?_c('video',{attrs:{"width":"350px","src":row.content
                                                            .video_url}}):_vm._e()]):_vm._e()])])]):_vm._e()])}),0),_vm._l((_vm.item.points),function(point,index){return _c('div',{key:index,class:("tab-pane fade " + (_vm.active === point ? 'show active' : '')),attrs:{"id":point}},[_c('div',_vm._l((_vm.data),function(row,idx){return _c('div',{key:idx,class:("" + (['Videos', 'Photos'].includes(point)
                                            ? 'col-6 col-md-4 col-lg-3 col-xl-2 d-flex'
                                            : 'shadow-sm mb-4')),attrs:{"cols":"6"}},[(
                                            row.engine === 'google' ||
                                            row.engine === 'bing'
                                        )?_c('div',[(row.type === 'result')?_c('div',[_c('b-row',{staticClass:"m-2"},[(row.thumbnails)?_c('b-col',{attrs:{"cols":"2"}},[_c('img',{attrs:{"src":row
                                                                .thumbnails[0],"width":"100"}})]):_vm._e(),_c('b-col',{attrs:{"cols":"8"}},[_c('b-row',[_c('b-col',[_c('b-row',[_c('b-col',[_c('b-row',[_c('h4',[_c('a',{attrs:{"href":row.url}},[_vm._v(_vm._s(row.title))])])]),_c('b-row',[_c('a',{attrs:{"href":row.url}},[_vm._v(" "+_vm._s(row.displayed_url)+" ")])]),_c('b-row',[_vm._v(" "+_vm._s(row.snippet)+" "),_c('a',{attrs:{"href":row.url}},[_vm._v("View more")])])],1)],1)],1)],1)],1)],1)],1):_vm._e(),(
                                                row.type ===
                                                'related_search'
                                            )?_c('div',[_c('h4',{staticClass:"m-4 p-4"},[_vm._v(" "+_vm._s(row.query)+" - "),_c('a',{attrs:{"href":("https://www.google.com/" + (row.url)),"target":"_blank"}},[_vm._v("See more")])])]):_vm._e(),(row.type === 'video')?_c('div',[_c('b-row',{staticClass:"m-2"},[_c('b-col',{attrs:{"cols":"8"}},[_c('b-row',[_c('b-col',[_c('b-row',[_c('b-col',[_c('b-row',[_c('h4',[_vm._v(" "+_vm._s(row.title)+" ")])]),_c('b-row',[_c('carousel-video',{attrs:{"videos":row.videos}})],1),_c('b-row',[_c('a',{attrs:{"href":row.url}},[_vm._v("View more")])])],1)],1)],1)],1)],1)],1)],1):_vm._e()]):_vm._e(),(row.engine === 'yahoo')?_c('div',{staticClass:"m-4"},[(row.type === 'result')?_c('div',[_c('a',{attrs:{"href":row.mainLink}},[_c('h6',[_vm._v(_vm._s(row.title))])]),_c('p',[_vm._v(_vm._s(row.description))]),(
                                                    row.additionalLinks
                                                        .length
                                                )?_c('div',[_vm._v(" Additional Links "),_vm._l((row.additionalLinks),function(links,index){return _c('div',{key:index},[_c('a',{attrs:{"href":links.url}},[_vm._v(_vm._s(links.text))])])})],2):_vm._e()]):_vm._e(),(
                                                row.type ===
                                                'related_search'
                                            )?_c('div',[_c('h4',{staticClass:"m-4 p-4"},[_c('a',{attrs:{"href":row.href,"target":"_blank"}},[_vm._v(_vm._s(row.text))])])]):_vm._e(),(row.type === 'video')?_c('div',[_c('div',{staticClass:"container mt-5"},[_c('div',{staticClass:"card mb-3",staticStyle:{"max-width":"540px"}},[_c('div',{staticClass:"row g-0"},[_c('div',{staticClass:"col-md-4"},[_c('img',{staticClass:"img-fluid rounded-start",attrs:{"src":row.imageUrl,"alt":"Video thumbnail"}})]),_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"card-title"},[_vm._v(" "+_vm._s(row.title)+" ")]),_c('p',{staticClass:"card-text"},[_vm._v(" "+_vm._s(row.date)+" | "+_vm._s(row.views)+" ")]),_c('p',{staticClass:"card-text"},[_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(row.source))])]),_c('p',{staticClass:"card-text"},[_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(row.timestamp))])]),_c('a',{staticClass:"btn btn-primary",attrs:{"href":row.url,"target":"_blank"}},[_vm._v("Watch Video")])])])])])])]):_vm._e(),(row.type === 'ad')?_c('div',[_c('div',{staticClass:"card mb-3"},[_c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"card-title"},[_c('a',{attrs:{"href":row.mainLinkUrl,"target":"_blank"}},[_vm._v(_vm._s(row.mainLinkText))])]),_c('p',{staticClass:"card-text"},[_vm._v(" "+_vm._s(row.promotionText)+" ")]),(
                                                            row
                                                                .additionalPromotions
                                                                .length
                                                        )?_c('div',{staticClass:"row"},_vm._l((row.additionalPromotions),function(promotion,index){return _c('div',{key:index,staticClass:"col-md-6"},[_c('a',{attrs:{"href":promotion.url,"target":"_blank"}},[_vm._v(_vm._s(promotion.text))])])}),0):_vm._e()])])]):_vm._e()]):_vm._e()])}),0)])})],2)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }
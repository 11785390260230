<template>
    <!-- The Modal -->
    <div class="modal fade" id="configEditModal" ref="editModal">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <!-- Modal Header -->
                <div class="modal-header">
                    <h4 class="modal-title">Edit Configuration</h4>
                    <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        @click="$emit('close')"
                    >
                        &times;
                    </button>
                </div>

                <!-- Modal body -->
                <div class="modal-body">
                    <div class="container-fluid">
                        <div class="row editor">
                            <div class="col-12 col-sm-12">
                                <div class="form" role="form" style="">
                                    <!-- section one -->
                                    <div class="row">
                                        <div class="col-12">
                                            <h5>Settings</h5>
                                            <br />
                                        </div>
                                        <div class="col-lg-12 col-md-12">
                                            <div class="row">
                                                <div class="col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        <label
                                                            >Engine
                                                            <strong
                                                                class="text-danger"
                                                                >*</strong
                                                            ></label
                                                        >
                                                        <select
                                                            v-model="
                                                                item.engine
                                                            "
                                                            class="form-control text-capitalize required"
                                                            required
                                                            @change="
                                                                this.users = []
                                                            "
                                                        >
                                                            <option
                                                                v-for="(
                                                                    engine,
                                                                    index
                                                                ) in engines"
                                                                :key="index"
                                                                :value="engine"
                                                            >
                                                                {{ engine }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        <label
                                                            >Search Type
                                                            <strong
                                                                class="text-danger"
                                                                >*</strong
                                                            ></label
                                                        >
                                                        <select
                                                            v-model="
                                                                item.search_type
                                                            "
                                                            class="form-control text-capitalize required"
                                                            required
                                                        >
                                                            <option
                                                                v-if="
                                                                    item.engine ===
                                                                    'yahoo'
                                                                "
                                                                value="api"
                                                            >
                                                                web
                                                            </option>
                                                            <option
                                                                v-else
                                                                v-for="(
                                                                    type, index
                                                                ) in types"
                                                                :key="index"
                                                                :value="type"
                                                            >
                                                                {{ type }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div
                                                    class="col-lg-6 col-md-6 mb-4"
                                                    v-if="
                                                        item.engine !== 'yahoo'
                                                    "
                                                >
                                                    <div class="control-styles">
                                                        <h6>Country</h6>
                                                        <ejs-dropdownlist
                                                            cssClass="e-outline"
                                                            id="sample-list3"
                                                            v-model="
                                                                item.country
                                                            "
                                                            :dataSource="
                                                                countries
                                                            "
                                                            :fields="fields"
                                                            :mode="true"
                                                            placeholder="Country"
                                                            :allowFiltering="
                                                                true
                                                            "
                                                            :change="loadStates"
                                                        ></ejs-dropdownlist>
                                                    </div>
                                                </div>
                                                <div
                                                    class="col-lg-6 col-md-6 col-sm-12"
                                                    v-if="
                                                        item.engine !== 'yahoo'
                                                    "
                                                >
                                                    <div class="control-styles">
                                                        <h6>State</h6>
                                                        <ejs-dropdownlist
                                                            cssClass="e-outline"
                                                            id="sample-list3"
                                                            v-model="item.state"
                                                            :dataSource="states"
                                                            :fields="fields"
                                                            :mode="true"
                                                            placeholder="State"
                                                            :allowFiltering="
                                                                true
                                                            "
                                                            :change="loadCities"
                                                        ></ejs-dropdownlist>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        <label
                                                            >Status
                                                            <strong
                                                                class="text-danger"
                                                                >*</strong
                                                            ></label
                                                        >
                                                        <select
                                                            v-model="
                                                                item.status
                                                            "
                                                            class="form-control text-capitalize required"
                                                            required
                                                        >
                                                            <option
                                                                v-for="(
                                                                    status,
                                                                    index
                                                                ) in statuses"
                                                                :key="index"
                                                                :value="status"
                                                            >
                                                                {{ status }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        <label
                                                            >Data Points
                                                            <strong
                                                                class="text-danger"
                                                                >*</strong
                                                            ></label
                                                        >
                                                        <ejs-multiselect
                                                            id="sample-list2"
                                                            v-model="
                                                                item.points
                                                            "
                                                            cssClass="e-outline"
                                                            :dataSource="
                                                                dataSource
                                                            "
                                                            mode="Box"
                                                        ></ejs-multiselect>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        <label
                                                            >Frequency
                                                            <strong
                                                                class="text-danger"
                                                                >*</strong
                                                            ></label
                                                        >
                                                        <select
                                                            v-model="
                                                                item.frequency
                                                            "
                                                            class="form-control text-capitalize required"
                                                            required
                                                        >
                                                            <option
                                                                v-for="(
                                                                    frequency,
                                                                    index
                                                                ) in frequencies"
                                                                :key="index"
                                                                :value="
                                                                    frequency
                                                                "
                                                            >
                                                                {{ frequency }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        <label
                                                            >Date Range
                                                            <strong
                                                                class="text-danger"
                                                                >*</strong
                                                            ></label
                                                        >
                                                        <ejs-daterangepicker
                                                            cssClass="e-outline"
                                                            v-model="
                                                                item.daterange
                                                            "
                                                        ></ejs-daterangepicker>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="control-styles">
                                                <h6>Search</h6>
                                                <span
                                                    class="e-input-group e-control-wrapper e-outline e-valid-input e-datetime-wrapper"
                                                >
                                                    <input
                                                        class="e-control e-textbox e-outline e-lib e-input e-keyboard"
                                                        placeholder="Input Search"
                                                        v-model="item.search"
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal-footer">
                    <div class="row pt-4">
                        <div class="col-12">
                            <div class="form-group text-center">
                                <button
                                    v-if="!item._id"
                                    type="submit"
                                    class="btn btn-primary btn-sm"
                                    @click.prevent="save"
                                >
                                    <span class="glyphicon glyphicon-ok"></span>
                                    Create Configuration
                                </button>
                                <button
                                    v-else
                                    type="submit"
                                    class="btn btn-primary btn-sm"
                                    @click.prevent="update"
                                >
                                    <span class="glyphicon glyphicon-ok"></span>
                                    Update Configuration
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import { MultiSelectComponent } from "@syncfusion/ej2-vue-dropdowns";

import { DateRangePickerPlugin } from "@syncfusion/ej2-vue-calendars";
import axios from "axios";
import swal from "sweetalert";
import moment from "moment/moment";

Vue.use(DateRangePickerPlugin);
let infiniteScroll = require("vue-infinite-scroll");

export default {
    name: "ConfigModal",
    props: ["item", "save", "update"],
    directives: {
        infiniteScroll,
    },
    components: { "ejs-multiselect": MultiSelectComponent },
    data() {
        return {
            fields: { text: "name", value: "code" },
            city_fields: { text: "city_name" },
            searchResults: [],
            args: {},
            ready: false,
            loading: false,
            cursor: null,
            search: null,
            isLoading: false,
            cancelTokenSource: null,
            providers: [],
            countries: [],
            states: [],
            cities: [],
            users: [],
            google: ["Results", "Related Search"],
            bing: ["Results", "Related Search", "Inline Videos"],
            yahoo: ["Ads", "Results", "Related Search", "Inline Videos"],
            types: ["serp"],
            engines: ["google", "bing", "yahoo"],
            statuses: ["active", "disabled"],
            frequencies: ["hourly", "daily", "monthly"],
        };
    },
    computed: {
        dataSource() {
            if (this.item.engine === "google") {
                return this.google;
            } else if (this.item.engine === "bing") {
                return this.bing;
            } else if (this.item.engine === "yahoo") {
                return this.yahoo;
            }
            return [];
        },
    },
    mounted() {
        this.loadCountries();
    },
    created() {
        // this.debouncedInputChange = debounce(this.searchUser, 600);
    },
    methods: {
        resetModal() {
            this.item = {
                country: null,
                state: null,
            };
        },
        filter() {
            this.users = [];
            this.cursor = null;
            console.log(this.item);
            let selectedEngine;
            if (this.item.engine === "google") {
                selectedEngine = "google_search";
            } else if (this.item.engine === "bing") {
                selectedEngine = "bing_search";
            } else {
                selectedEngine = this.item.engine;
            }
            const data = {
                query: this.item.search,
                country: this.item.country,
                location: this.item.state,
                search_engine: selectedEngine,
            };
            console.log(this.item, this.item);
            const response = axios.post("api/api/v1/realtime/serp", data, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization:
                        "Basic YWNjb3VudC1hZHJldHJlYXZlci1waXBlbGluZS1zY3JhcGVyOlg3ODQ4VzFqd3VvNA==",
                },
            });
            console.log(response);
        },
        async loadCountries() {
            try {
                const resp = await axios.get(
                    `${this.$root.serverUrl}/admin/countries`
                );
                this.countries = resp.data.data.filter((e) => e.code === "US");
            } catch (error) {
                console.error("Error loading countries:", error);
            }
        },
        async loadStates(args) {
            const request = { filters: { country_iso_code: args.value } };
            try {
                const resp = await axios.get(
                    `${this.$root.serverUrl}/admin/geos/state`,
                    { params: request }
                );
                this.states = resp.data ? resp.data.data : [];
            } catch (error) {
                console.error("Error loading states:", error);
            }
        },
        async loadCities(args) {
            const request = {
                filters: {
                    country_iso_code: "US",
                    subdivision_1_iso_code: args.value,
                },
            };
            try {
                const resp = await axios.get(
                    `${this.$root.serverUrl}/admin/geos/city`,
                    { params: request }
                );
                this.cities = resp.data ? resp.data.data : [];
            } catch (error) {
                console.error("Error loading cities:", error);
            }
        },
        toDate(date) {
            return moment(date).format("YYYY-MM-DD hh:mm:ss");
        },
        verify(user) {
            console.log(this.item);
            console.log(user);
            if (!this.item._id) {
                console.log("no id");
                this.item.verified = user;
                this.$forceUpdate();
                return;
            }
            axios
                .put(
                    `${this.$root.serverUrl}/admin/browser-search/${this.item._id}`,
                    { args: { verified: user } }
                )
                .then((resp) => {
                    console.log("request");
                    if (resp.data && resp.data.error) {
                        swal({
                            title: "Error",
                            text: "Unable to process your request",
                            icon: "error",
                        });
                    } else {
                        swal({
                            title: "Success",
                            text: "Account verified",
                            icon: "success",
                        });
                        this.item.verified = user;
                        this.$forceUpdate();
                    }
                })
                .catch((err) => {
                    console.log(err);
                    swal({
                        title: "Error",
                        text: "Unable to process your request",
                        icon: "error",
                    });
                });
        },
        searchCursor: function () {
            var request = {
                search: this.item.search,
                cursor: this.cursor,
                search_category: this.item.search_category,
            };
            if (!this.cursor || this.loading) return;
            this.loading = true;

            if (this.cancelTokenSource) {
                this.cancelTokenSource.cancel(
                    "Operation canceled due to new request."
                );
            }

            this.cancelTokenSource = axios.CancelToken.source();

            return axios
                .get(
                    `${
                        this.$root.serverUrl
                    }/admin/browser-search/${this.item.platform.toLowerCase()}/users`,
                    {
                        params: request,
                        cancelToken: this.cancelTokenSource.token,
                    }
                )
                .then((resp) => {
                    //Store the stats
                    if (!resp.data) return;
                    let users =
                        resp.data && resp.data.users ? resp.data.users : [];
                    this.users.push(...users);
                    this.cursor =
                        resp.data && resp.data.cursor ? resp.data.cursor : null;
                })
                .catch((err) => {
                    if (!axios.isCancel(err)) {
                        console.log(err);
                        swal({
                            title: "Error",
                            text: "Unable to process your request",
                            icon: "error",
                        });
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        searchUser: function () {
            var request = {
                search: this.item.search,
                search_category: this.item.search_category,
            };
            this.loading = true;

            if (this.cancelTokenSource) {
                this.cancelTokenSource.cancel(
                    "Operation canceled due to new request."
                );
            }

            this.cancelTokenSource = axios.CancelToken.source();

            return axios
                .get(
                    `${
                        this.$root.serverUrl
                    }/admin/browser-search/${this.item.platform.toLowerCase()}/users`,
                    {
                        params: request,
                        cancelToken: this.cancelTokenSource.token,
                    }
                )
                .then((resp) => {
                    //Store the stats
                    if (!resp.data) return;
                    this.users =
                        resp.data && resp.data.users ? resp.data.users : [];
                    this.cursor =
                        resp.data && resp.data.cursor ? resp.data.cursor : null;
                })
                .catch((err) => {
                    if (!axios.isCancel(err)) {
                        console.log(err);
                        swal({
                            title: "Error",
                            text: "Unable to process your request",
                            icon: "error",
                        });
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
};
</script>

<style scoped>
@import "../../../../node_modules/@syncfusion/ej2-vue-calendars/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-dropdowns/styles/material.css";

.modal-dialog {
    height: 900px;
    display: flex;
    max-width: 800px;
}

.modal-header {
    background: #0d0d48;
}

.modal-title {
    color: #ffffff;
}

.close {
    color: #ffffff;
}

.modal-body {
    overflow: scroll;
}

.info-sec i {
    font-size: 100px;
}

.v-select2-container {
    width: 100%; /* Set the width to 100% */
}

.select2-container .select2-selection--single {
    height: 38px; /* Set the width to 100% */
}

.select2-container--default
    .select2-selection--single
    .select2-selection__rendered {
    line-height: 38px;
}
</style>

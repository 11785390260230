<template>
    <div>
        <div class="row">
            <div class="col-md-6" v-for="(video, index) in videos" :key="index">
                <a :href="`https://www.bing.com${video.url}`" target="_blank">{{ video.title }}</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CarouselVideo',
    props : {
        videos: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            currentSlide: 0,
        }
    }
}
</script>

